import React from 'react'
import { styled } from '@mui/material'

const ContentContainer = ({children, backgroundColor}) => {
    const Container = styled('div')({
        width:'100%',
        height:'100%',
        backgroundColor:backgroundColor
    })
  return (
    <Container>{children}</Container>
  )
}

export default ContentContainer