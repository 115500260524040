import React, {useContext} from "react";
import loadable from '@loadable/component'
import useIsMobile from "../../hooks/useIsMobile";
import Section from "../section/section"
import { useTheme } from '@mui/material'
import { ButtonOrderContext } from '../../contexts/buttonOrderContext'

const SectionModal = loadable(()=>import('../sectionModal/sectionModal'))
const ContactSection = loadable(()=>import( '../contactSection/contactSection'))
const VideosSection = loadable(()=>import( '../videosSection/videosSection'))
const ServicesSection = loadable(()=>import( "../servicesSection/servicesSection"))
const LandingModal = loadable(()=>import( '../landingModal/landingModal'))

const SectionModals = ({videos, videoThumbnails, images}) => {
    const theme = useTheme()
    const { isMobileLandscape } = useIsMobile()
    const { order, openLandingModal } = useContext(ButtonOrderContext)
    const buttonOrder = order
  return (
    <>
      <SectionModal
        open={!openLandingModal && !buttonOrder.contact.end}
        close={!openLandingModal && !buttonOrder.contact.start}
      >
        <Section name="contact">
          <ContactSection />
        </Section>
      </SectionModal>
      <SectionModal
        open={!openLandingModal && !buttonOrder.videos.end}
        close={!openLandingModal && !buttonOrder.videos.start}
      >
        <Section name="videos" backgroundColor={theme.palette.gradients.lavenderBlush}>
          <VideosSection
            videos={videos}
            videoThumbnails={videoThumbnails}
            image={images.cinema}
            isMobileLandscape={isMobileLandscape}
          />
        </Section>
      </SectionModal>
      <SectionModal
        open={!openLandingModal && !buttonOrder.services.end}
        close={!openLandingModal && !buttonOrder.services.start}
      >
        <Section name="services">
          <ServicesSection />
        </Section>
      </SectionModal>
      <SectionModal
        open={openLandingModal}
        close={!openLandingModal}
      >
        <Section name="welcome">
          <LandingModal/>
        </Section>
      </SectionModal>
    </>
  );
};

export default SectionModals;
